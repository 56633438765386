import styled from "styled-components";
import { sizes } from "./screenSizes";

// media sizes for different devices

const media = {
  xs: `
    @media(max-width: ${sizes.xs}px) {
    flex-basis: 100%;
    }
    `,
  small: `
    @media(max-width: ${sizes.small}px) {
    flex-basis: 100%;
    }
    `,
  medium: `
    @media(max-width: ${sizes.medium}px) {
    flex-basis: 100%;
    }
    `,
  large: `
    @media(max-width: ${sizes.large}px) {
    flex-basis: 100%;
    }
    `,
  xl: `
    @media(max-width: ${sizes.xl}px) {
    flex-basis: 100%;
    }
    `,
};

export const Grid = styled.div`
  overflow: ${props => (props.isNotHidden ? `initial` : `hidden`)};
`;

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Col = styled.div`
  flex: ${props => props.size};
  ${props => props.breakSize && media[props.breakSize]}
`;

// Body and Container styles
export const SectionContainer = styled.div`
  padding: 40px 80px;
  position: relative;
  z-index: 1;
  justify-content: center;
  display: flex;

  @media (max-width: ${sizes.large}px) {
    padding: 40px 20px;
  }

  @media (max-width: ${sizes.medium}px) {
    padding: 60px 20px;
  }
`;

export const TextContainer = styled.div`
  width: 100%;
  padding: 40px 80px 80px 80px;
  position: relative;
  z-index: 1;
  justify-content: center;
  display: flex;
  flex-direction: ${props => (props.isColumn ? "column" : "row")};

  @media (max-width: ${sizes.large}px) {
    padding: 40px 20px;
  }

  @media (max-width: ${sizes.medium}px) {
    padding: 60px 20px;
    flex-wrap: wrap;s
  }

  @media (max-width: ${sizes.small}px) {
    padding: 10px 10px;
  }
`;

export const InternalContainer = styled.div`
  max-width: 1440px;
`;
