import React from "react";

import {
  HamburgerContainer,
  Bar,
  HamburgerMenu,
  Bar1,
  Bar2,
  MenuContainer,
  MenuContentContainer,
  HambHeaderLink,
  HambHeaderPage,
  HambLinkContainer,
  LinkContainer,
  HambButton,
  ResumeLink,
} from "./hamburger_styles";

import { LogoWrapper } from "./header_styles";
import { Link } from "gatsby";

import Logo from "../../images/logo.svg";

import pdf from "../../resume/anika_jagow.pdf";

const Hamburger = props => {
  const [isOpen, setIsOpen] = React.useState(false);
  const onClick = props.openJoinNow;

  const toggle = () => setIsOpen(!isOpen);
  return (
    <HamburgerContainer onClick={toggle}>
      {renderStep(isOpen, onClick)}
    </HamburgerContainer>
  );
};

function renderStep(isOpen, onClick) {
  if (!isOpen) {
    return (
      <HamburgerMenu>
        <Bar></Bar>
        <Bar></Bar>
        <Bar></Bar>
      </HamburgerMenu>
    );
  } else {
    return (
      <MenuContainer>
        <LinkContainer>
          <Link to="/" style={{ lineHeight: 0 }}>
            <LogoWrapper><Logo/></LogoWrapper>
          </Link>
        </LinkContainer>
        <HamburgerMenu isOpen>
          <Bar1></Bar1>
          <Bar2></Bar2>
        </HamburgerMenu>

        <MenuContentContainer>
          <HambLinkContainer>
            <HambHeaderLink to="/" activeStyle={{ color: "red" }}>
              <HambHeaderPage>Home</HambHeaderPage>
            </HambHeaderLink>
            <HambHeaderLink to="/#about" activeStyle={{ color: "red" }}>
              <HambHeaderPage>About</HambHeaderPage>
            </HambHeaderLink>
            <HambHeaderLink to="/#projects" activeStyle={{ color: "red" }}>
              <HambHeaderPage>Projects</HambHeaderPage>
            </HambHeaderLink>
            <ResumeLink href={pdf} target="_blank">
              <HambHeaderPage>Resume</HambHeaderPage>
            </ResumeLink>
          </HambLinkContainer>
        </MenuContentContainer>
      </MenuContainer>
    );
  }
}

export default Hamburger;
