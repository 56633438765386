import styled from "styled-components";

import { Typography } from "../base_components/typography";
import { Link } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";

import { sizes } from "../base_components/screenSizes";

import { colorMap } from "../base_components/colors";

export const HeaderWrapper = styled.header`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  font-family: "Noto Sans", sans-serif;
  padding-top: 25px;
  align-items: center;
  height: 75px;
  background-color: ${colorMap.white};
  z-index: 10000;
  position: fixed;
  width: 100%;
  top: 0;
  margin: 0 auto;
  position: relative;
  position: sticky;
  max-width: 1440px;
`;

export const LogoWrapper = styled.div`
  padding-left: 80px;
  height: 50px;

  svg {
    width: 50px;
  }

  @media (max-width: ${sizes.large}px) {
    padding-left: 20px;
  }

  @media (max-width: ${sizes.medium}px) {
    display: flex;
    padding-left: 20px;
  }

  @media (max-width: ${sizes.small}px) {
    display: flex;
    padding-left: 15px;
  }
`;

export const HamburgerMenuContainer = styled.div`
  display: none;
  @media (max-width: 975px) {
    display: flex;
  }
`;

export const HeaderLinkGroup = styled.div`
  display: flex;
  align-items: center;
  width: auto;
  justify-content: space-between;
  margin-right: 70px;

  @media (max-width: 991px) {
    margin-right: 5px;
  }
  @media (max-width: 975px) {
    display: none;
  }
`;
export const HeaderPageLink = styled(Link)`
  text-decoration: none;
  color: ${colorMap.fontBlue};
  border-bottom: 4px solid rgba(56, 206, 194, 0);
  margin: 0 14px 0 14px;
  padding-bottom: 4px;

  :hover {
    border-bottom: 4px solid ${colorMap.brandBlue};
  }
`;

export const HeaderLink = styled(AnchorLink)`
  text-decoration: none;
  color: ${colorMap.fontBlue};
  border-bottom: 4px solid rgba(56, 206, 194, 0);
  margin: 0 14px 0 14px;
  padding-bottom: 4px;

  :hover {
    border-bottom: 4px solid ${colorMap.brandBlue};
  }
`;

export const ResumeLink = styled.a`
  text-decoration: none;
  color: ${colorMap.fontBlue};
  border-bottom: 4px solid rgba(56, 206, 194, 0);
  margin: 0 14px 0 14px;
  padding-bottom: 4px;

  :hover {
    border-bottom: 4px solid ${colorMap.brandBlue};
  }
`;

export const HeaderLogo = styled.img`
  width: 170px;
  padding-bottom: 15px;

  @media (max-width: 975px) {
    padding-bottom: 0;
  }
`;
export const HeaderPage = styled(Typography.SH1)`
  font-size: 1em;
  line-height: normal;
  margin-bottom: 0px;
`;

export const HeaderButton = styled.div`
  font-size: 0.83em;
  border-radius: 40px;
  margin-bottom: 8px;
  padding: 0px 20px;
  background-color: ${colorMap.orange};
  height: 35px;
  color: ${colorMap.white};
  display: flex;
  align-items: center;
  padding-right: 20px;
  padding-left: 20px;
  cursor: pointer;
`;
export const HeaderContainer = styled.div`
  position: absolute;
  z-index: 2;
  width: 100%;
  margin: 0 auto;
`;
