import styled from "styled-components";

import { Col, Row, SectionContainer } from "../base_components/layout";
import { sizes } from "../base_components/screenSizes";
import { colorMap } from "../base_components/colors";
import { Typography } from "../base_components/typography";

export const FooterContainer = styled.footer`
  background: ${colorMap.brandPurple};
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1440px;
  margin: 0 auto;
`;

export const MadeWithLove = styled(Typography.SH1)`
  color: ${colorMap.white};
`;
