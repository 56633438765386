export const white = "#ffffff";
export const brandPurple = "#695F8F";
export const brandPink = "#FFACB7";
export const brandBlue = "#D5E2FD";
export const brandYellow = "#FFEDAC";

export const fontBlue = "#183571";
export const fontLightBlue = "#6886C5";

export const colorMap = {
  white: white,
  brandPurple: brandPurple,
  brandPink: brandPink,
  brandBlue: brandBlue,
  brandYellow: brandYellow,
  fontBlue: fontBlue,
  fontLightBlue: fontLightBlue,
};
