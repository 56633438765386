import React from "react";

import { FooterContainer, MadeWithLove } from "./footer_styles";

export const Footer = props => {
  return (
    <FooterContainer>
      <MadeWithLove>made with ❤︎</MadeWithLove>
    </FooterContainer>
  );
};
