/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";

import Header from "./navbar/header";
import { Footer } from "./footer/footer";
import "./layout.css";

const Layout = props => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  const isShowHeader = props.showHeader;

  return (
    <>
      {isShowHeader && <Header siteTitle={data.site.siteMetadata.title} />}

      <div
        style={{
          margin: `0 auto`,
        }}
      >
        <main>{props.children}</main>
      </div>
      <Footer />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
