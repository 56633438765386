import PropTypes from "prop-types";
import React, { useState } from "react";
import {
  HeaderWrapper,
  HeaderLinkGroup,
  HeaderLink,
  HeaderPageLink,
  ResumeLink,
  HeaderContainer,
  HeaderPage,
  LogoWrapper,
  HamburgerMenuContainer,
} from "./header_styles";
import { Link } from "gatsby";

import Hamburger from "./hamburger";

import { colorMap } from "../base_components/colors";

import pdf from "../../resume/anika_jagow.pdf";

import Logo from "../../images/logo.svg";

const Header = ({ siteTitle }) => {
  const [isModalVisible, setModalVisible] = useState(false);

  return (
    <HeaderContainer>
      <HeaderWrapper>
        <Link
          to="/"
          style={{ lineHeight: 0, position: "absolute", top: "10px", left: 0 }}
        >
          <LogoWrapper>
            <Logo />
          </LogoWrapper>
        </Link>
        <HamburgerMenuContainer>
          {<Hamburger openJoinNow={setModalVisible} />}
        </HamburgerMenuContainer>
        <HeaderLinkGroup>
          <HeaderPageLink
            to="/"
            activeStyle={{ borderBottom: `4px solid ${colorMap.brandBlue}` }}
          >
            <HeaderPage>Home</HeaderPage>
          </HeaderPageLink>
          <HeaderLink
            to="/#about"
            activeStyle={{ borderBottom: `4px solid ${colorMap.brandBlue}` }}
          >
            <HeaderPage>About</HeaderPage>
          </HeaderLink>
          <HeaderLink
            to="/#projects"
            activeStyle={{ borderBottom: `4px solid ${colorMap.brandBlue}` }}
          >
            <HeaderPage>Projects</HeaderPage>
          </HeaderLink>
          <ResumeLink href={pdf} target="_blank">
            <HeaderPage>Resume</HeaderPage>
          </ResumeLink>
        </HeaderLinkGroup>
      </HeaderWrapper>
    </HeaderContainer>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: `TestTitle`,
};

export default Header;
