import styled from "styled-components";
import { sizes } from "./screenSizes";

import React from "react";

// Font Styles

const Base = styled.p`
  margin: 0;
  text-align: ${props => (props.textAlign ? `initial` : `hidden`)};
`;

export const H1 = styled(Base)`
  font-family: "Merriweather";
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 66px;
  margin-bottom: 20px;

  @media (max-width: ${sizes.medium}px) {
    font-size: 32px;
    line-height: 40px;
  }

  @media (max-width: ${sizes.small}px) {
    font-size: 28px;
    line-height: 38px;
  }
`;

export const H2 = styled(Base)`
  font-family: "Merriweather";
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 40px;
  margin-bottom: 16px;

  @media (max-width: ${sizes.medium}px) {
    font-size: 24px;
    line-height: 36px;
  }

  @media (max-width: ${sizes.small}px) {
    font-size: 20px;
    line-height: 26px;
  }
`;

export const SH1 = styled(Base)`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;

  @media (max-width: ${sizes.medium}px) {
    font-size: 20px;
    line-height: 28px;
  }

  @media (max-width: ${sizes.small}px) {
    font-size: 18px;
    line-height: 22px;
  }
`;

export const body = styled(Base)`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  @media (max-width: ${sizes.medium}px) {
    font-size: 16px;
    line-height: 24px;
  }

  @media (max-width: ${sizes.small}px) {
    font-size: 14px;
    line-height: 20px;
  }
`;

export const Typography = { H1: H1, H2: H2, SH1: SH1, body: body };
